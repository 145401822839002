






























import useMisc from "@/use/misc";
import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    OMailAddressAdd: () =>
      import(
        "@/components/organisms/globalTools/mailAddresses/o-mail-address-add.vue"
      ),
  },

  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const model = reactive<{
      data: any;
    }>({
      data: {
        type: "withLogin",
        name: "",
        email: "",
        password: "",
        server: "",
        port: 587,
        defaultAddress: false,
      },
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      errors: computed((): FormError[] => {
        const errors: FormError[] = [];

        if (!model.data.email)
          errors.push({ error: root.$tc("layout.misc.validEmailAddress") });
        if (!model.data.password)
          errors.push({ error: root.$tc("layout.misc.emptyPassword") });
        if (!model.data.server && model.data.type === "withLogin")
          errors.push({ error: root.$tc("rules.server") });

        return errors;
      }),
    });

    const isFormValid = computed(() => !state.errors.length);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        type: model.data.type,
        name: model.data.name,
        email: model.data.email,
        password: model.data.password || undefined,
        server: model.data.server || undefined,
        port: model.data.port || undefined,
        defaultAddress: model.data.defaultAddress,
      };

      state.loading = true;

      axiosInstance
        .post("mail-account", data)
        .then(() => {
          state.success = true;
          model.data = {
            type: "withLogin",
            name: "",
            email: "",
            password: "",
            server: "",
            port: 587,
            defaultAddress: false,
          };
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.globalTools.mailAddresses.add.success"),
          });
          setTimeout(() => {
            goBack("panel.mailAddress.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      model,
      state,
      isFormValid,
      onSubmit,
      getErrorMessage,
    };
  },
});
